<template>
  <el-row class="table">
    <span class="title">课程管理</span>
    <el-tabs
      v-model="tabName"
      class="tabs-con"
      style="min-height: 200px;margin-top: 35px;width: 100%;background-color: #FFFFFF;padding-left: 20px;"
      @tab-click="changeTab"
    >
<!--      &lt;!&ndash; 基本信息 &ndash;&gt;-->
<!--      <el-tab-pane label="基本信息" name="baseInfo">-->
<!--        <base-info-->
<!--          :curriculumID="curriculumID"-->
<!--          :CourseInfo="CourseInfo"-->
<!--          @updatBaseInfo="updatBaseInfo"-->
<!--        ></base-info>-->
<!--      </el-tab-pane>-->
      <!-- 课程计划 -->
      <el-tab-pane label="课程计划" name="LessonPlan">
        <curriculum-plan
          :curriculumID="curriculumID"
          :CourseInfo="CourseInfo"
        ></curriculum-plan>
      </el-tab-pane>
      <el-tab-pane label="成员管理" name="LessonStudent">
        <student-list></student-list>
      </el-tab-pane>
<!--      <el-tab-pane label="排行榜" name="rankList" v-if="CourseInfo.type === '刷题'">-->
<!--        <rank-list :CourseInfo="CourseInfo"></rank-list>-->
<!--      </el-tab-pane>-->
    </el-tabs>

    <!-- back top -->
    <el-backtop :bottom="40" :right="60" :visibility-height="50" target=".table"></el-backtop>

    <!-- taskList: {{taskList}}
    <hr>
    {{curriculumList}} -->
  </el-row>
</template>

<script>
// import getCourseType from '@/mixins/getCourseType'
// import baseInfo from '@/components/research/curriculum-manage/basicInfo'
import studentList from '@/components/research/curriculum-manage/studentList'
// import rankList from '@/components/research/curriculum-manage/rankList'
import curriculumPlan from '@/components/senate/detail-pane/class-catalogue'
// import { getCourseInfo, getTaskList, getCourseUnitList } from '@/api/research/curriculum'
import { getCourseInfo, getTaskList, getCourseUnitList} from '@/api/research/curriculum'
import { getCourseUnitListOnly, getInfo } from '@/api/senate/class'

export default {
  // mixins: [getCourseType],
  components: {
    // baseInfo,
    curriculumPlan,
    studentList,
    // rankList
  },
  watch: {
    // $route (to, from) {
    //   // console.log('to', to)
    //   // console.log('from', from)
    //   if (to.path === from.path) {
    //     this.$router.push({
    //       path: to.path,
    //       query: {
    //         curriculumID: from.query.curriculumID
    //       }
    //     })
    //   }
    // },
    tabName (val) {
      this.$router.push({
        path: this.$route.path,
        query: {
          curriculumID: this.curriculumID,
          courseId:this.curriculumID,
          type: this.$route.query.type,
          tab: val,
        }
      })
    },
  },
  data () {
    return {
      tabName: 'LessonPlan',
      curriculumID: '', // 课程id
      CourseInfo: {}, // 存储课程基本信息
      curriculumList: [], // 课节列表
      taskList: [], // 任务列表,
      curriculumArr: [],
    }
  },
  created () {
    if (Boolean(this.$route.query.tab)) {
      this.tabName = this.$route.query.tab
    }
    this.curriculumArr = []
    this.curriculumID = this.$route.query.curriculumID // 课程 id
    this.getCourseInfo()

    // 课程计划 - 仅列表
    // this.getCourseUnitListOnly()
  },
  methods: {
    // 返回上级
    back () {
      this.$router.go(-1)
    },

    async getCourseUnitListOnly () {
      const res1 = await getCourseUnitListOnly({
        pageNum: 1,
        pageSize: 10000,
        courseId: this.curriculumID,
        status: '已启用',
      })
      this.curriculumList = res1.body.list
      this.curriculumArr = [...this.curriculumList]
    },

    // tab 页面切换
    changeTab () {
      if (this.tabName === 'baseInfo') {
        this.getCourseInfo()
      } else if (this.tabName === 'LessonPlan') {
      }
    },

    // 数据重组
    // async getCurriculumArr () {
    //   // 获取课节列表
    //   const res1 = await getCourseUnitList({
    //     courseId: this.curriculumID,
    //     pageNum: 1,
    //     pageSize: 1000
    //   })
    //   this.curriculumList = res1.body.list
    //   // 获取(课节, 任务) 列表
    //   const res2 = await getTaskList({
    //     pageNum: 1,
    //     pageSize: 1000,
    //     courseId: this.curriculumID
    //   })
    //   this.taskList = res2.body.list
    //   // 整合数据
    //   this.curriculumArr = [...this.curriculumList]
    //   for (let index in this.curriculumArr) {
    //     for (let i in this.taskList) {
    //       if (this.curriculumArr[index].id === this.taskList[i].courseUnitId) {
    //         if (!this.curriculumArr[index].children) {
    //           this.curriculumArr[index].children = []
    //         }
    //         this.curriculumArr[index].children.push(this.taskList[i])
    //       }
    //     }
    //   }
    // },
    // 根据 id 获取课程基本信息
    async getCourseInfo () {
      const res = await getCourseInfo({
        id: this.curriculumID
      })
      this.CourseInfo = { ...res.body }
    },

    // 课节排序更新数据
    updateData () {
      this.taskList = []
      this.curriculumList = []
    },

    // 更新基本信息
    updatBaseInfo () {
      this.getCourseInfo()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../style/table.scss";
/deep/ .el-tabs__nav {
  background: #ffffff;
}

/deep/ .el-tabs--left .el-tabs__item.is-left {
  text-align: center;
}

/deep/ .el-tabs__content {
  background: #ffffff;
}

/deep/ .el-tabs--left .el-tabs__header.is-left {
  margin-right: 35px;
}

/deep/ .el-tabs__nav-wrap::after {
  background: transparent;
}
</style>
