<template>
  <el-row class="table">
    <el-row class="row-bg">
      <el-input
        class="tb-sm-input"
        v-model.trim="queryData.inputVal"
        placeholder="请输入学号"
        @keyup.enter.native="search">
      </el-input>
      <el-input
        class="tb-sm-input"
        v-model.trim="queryData.inputName"
        placeholder="请输入姓名"
        @keyup.enter.native="search">
      </el-input>
<!--      <el-select v-model="queryData.seleState" placeholder="选择状态" class="tb-smselect">-->
<!--        <el-option v-for="(item, index) in queState" :key="index" :label="item.label" :value="item.value"></el-option>-->
<!--      </el-select>-->
      <el-button type="primary" size="small" @click="search">搜 索</el-button>
      <el-button type="primary" size="small" @click="add">新 增</el-button>
      <el-button type="info" size="small" @click="reset">重 置</el-button>
      <el-button type="info" @click="switchCourseBatch('启用')" size="small">批量开放</el-button>
      <el-button type="info" @click="switchCourseBatch('关闭')" size="small">批量关闭</el-button>
    </el-row>
     <el-table
      :data="dataList"
      class="tb-list ml-10"
      @selection-change="handleSelectionChange"
      style="width: 100%;margin-bottom: 20px;">
       <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="学号" align="center" prop="loginName"></el-table-column>
        <el-table-column label="姓名" align="center" prop="userName"></el-table-column>
        <el-table-column label="所属机构" align="center" prop="merchantName"></el-table-column>
        <el-table-column label="学习进度" align="center" width="300">
          <template slot-scope="scope">
            <div style="display: flex;align-items: center">
              <el-progress style="width: 80%" :percentage="scope.row.taskCompleteNum * 100/scope.row.taskTNum" :format="format"></el-progress>
              <span>({{ scope.row.taskCompleteNum }}/{{scope.row.taskTNum}})</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="授权时间" align="center" prop="createTime"></el-table-column>
        <el-table-column label="当前状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.useStatus === '关闭'" style="color:  #F5222D">{{ scope.row.useStatus }}</span>
            <span v-else style="color:  #52C41A">{{ scope.row.useStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="230">
          <template slot-scope="scope">
              <el-button type="text" style="color: #F5222D !important;" @click="switchCourse(scope.row)" v-if="scope.row.useStatus === '启用'">关闭课程</el-button>
            <el-button type="text" style="color: #52C41A !important;" @click="switchCourse(scope.row)" v-else>开放课程</el-button>
          </template>
        </el-table-column>
     </el-table>

     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- 学员课程dialog -->
    <student-course :resetUse="studentCourseObj" :show="dialogStuCourse" @close="dialogStuCourse=false"></student-course>
    <!-- 课程管理新增学员 -->
    <course-teacher :show="studentDialog" @close="studentDialog=false" @updateView="getCourseUserWhithTaskInfo"></course-teacher>
  </el-row>
</template>

<script>
import { getCourseUserWhithTaskInfo, updateUseState } from '@/api/research/curriculum'
import studentCourse from '@/components/senate/detail-pane/student-course'
import courseTeacher from '@/components/research/curriculum-manage/components/course-teacher'

export default {
  components: { studentCourse, courseTeacher },
  data () {
    return {
      dataList: [],
      queryData: {
        inputVal: '',
        inputName: '',
        seleState: ''
      },
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      // queState: [
      //   {
      //     value: '开放',
      //     label: '启用'
      //   },
      //   {
      //     value: '关闭',
      //     label: '关闭'
      //   }
      // ],
      studentCourseObj: null,
      dialogStuCourse: false,
      studentDialog: false,
      multipleSelection: []
    }
  },
  created () {
    this.getCourseUserWhithTaskInfo()
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    format() {
      return '';
    },
    async switchCourseBatch(flag) {
      if (this.multipleSelection.length !== 0) {
        let idsArray = []
        this.multipleSelection.forEach(item => {
          idsArray.push(item.id)
        })

        await updateUseState({
          ids: idsArray,
          useStatus: flag,
        })
        window.$msg('操作成功')
        this.getCourseUserWhithTaskInfo()
      }else {
        window.$msg('请先选择学员后在执行批量操作',2)
        return false
      }
    },
    async getCourseUserWhithTaskInfo () {
      const res = await getCourseUserWhithTaskInfo({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        needCount:true,
        courseId: this.$route.query.curriculumID,
        userName: this.queryData.inputName ? this.queryData.inputName : null,
        loginName: this.queryData.inputVal ? this.queryData.inputVal : null,
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    // 课程状态切换
    async switchCourse (row) {
      let idsArray = []
      idsArray.push(row.id)
      await updateUseState({
        ids: idsArray,
        useStatus: row.useStatus === '启用' ? '关闭' : '启用',
      })
      window.$msg('操作成功')
      this.getCourseUserWhithTaskInfo()
    },

    search () {
      this.pagination.currentPage = 1
      this.getCourseUserWhithTaskInfo()
    },

    reset () {
      this.queryData.inputVal = null
      this.queryData.inputName = null
      this.queryData.seleState = null
    },

    add () {
      this.studentDialog = true
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getCourseUserWhithTaskInfo()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getCourseUserWhithTaskInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../style/table.scss";
</style>
