<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    width="70%"
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>课程管理新增成员</span>
    </div>
    <el-tree
      :data="treeList"
      show-checkbox
      ref="eltreeData"
      node-key="id"
      :default-expand-all="true"
      :default-checked-keys="checkedArray"
      :props="defaultProps">
      <!-- :default-checked-keys 设置默认选中的节点  default-expand-all 默认展开所有节点 :expand-on-click-node="false" 只有点击三角形的时候才会展开或者关闭，点击节点无效 :current-node-key 默认选中的节点 highlight-current 节点高亮（据说这个要后面防止默认节点不高亮） @node-click=点击节点-->
    </el-tree>
    <el-row slot="footer">
      <el-button type="primary" size="small" @click="saveForm" class="dialog-btn">保 存</el-button>
      <el-button @click="close" size="small" type="info" class="dialog-btn">取 消</el-button>
    </el-row>
  </el-dialog>
</template>
<script>
import {
  getMerchantListWhithEmployee,
  getUserListByCourseTrain,
  batchCourseUser,
} from '@/api/research/curriculum'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      checkedArray: [],
      currentId: "",
      treeList: [],
      defaultProps: {
        children: "employees",
        label: "name",
      },
      deId: [],
    };
  },

  methods: {
    openDialog() {
      this.treeList = []
      this.getMerchantList()
    },
    // 获取全部商户老师列表
    async getMerchantList() {
      const res = await getMerchantListWhithEmployee()
      this.treeList = res.body
      this.getUserListByCourse()
      // this.treeList.forEach(item => {
      //   item.employees.forEach(value => {
      //     if(value.ifAdded === '1') {
      //       this.checkedArray.push(value.id)
      //     }
      //   })
      // })
    },
    async getUserListByCourse() {
      const res = await getUserListByCourseTrain({
        courseId: this.$route.query.courseId
      })
      this.checkedArray = []
      res.body.forEach(item => {
        this.checkedArray.push(item.userId)
      })
    },

    async saveForm() {
      let thembArray = this.$refs.eltreeData.getCheckedNodes()
      let userIdsArray = []
      thembArray.forEach(item => {
        if (item.merchantId !== undefined && item.merchantId !== null) {
          userIdsArray.push(item.id)
        }
      })
      let responseData = await batchCourseUser({
          courseId: this.$route.query.courseId,
          userIds:userIdsArray
        }
      );

      if (responseData && responseData.state == "success") {
        window.$msg('保存成功')
        this.$emit('updateView')
        this.getMerchantList()
      }
    },
    close() {
      this.$emit('close')
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form .el-form-item {
  margin-bottom: 30px;
}

// @import "~@/style/dialog.scss";
@import "../../../../style/dialog.scss";
/deep/ .el-tree-node__label {
  margin-left: 10px;
}
</style>
